import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import ProtoV1 from "../../images/twiage/proto_v1.gif"
import ProtoV2 from "../../images/twiage/proto_v2.gif"
import oldDashboard from "../../images/twiage/dashboard_old.jpg"
import newDashboard from "../../images/twiage/dashboard_new.jpg"
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import Footer from '../../components/footer';
import Wrapper from '../../components/wrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Logo from "../../images/twiage/logo.inline.svg";

const IndexPage = () => {
  return (
    <Layout navtype="dark" title="Twiage">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true" offset={0}>
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../images/twiage/head.jpg"
          alt="Twiage"
          className="hero-image"
        />
      </AnimationOnScroll>
      <Wrapper className="first">
        <Logo alt="Twiage" className="project-logo" />
      </Wrapper>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper>
        <p>
          2019. <a target="_blank" rel="noreferrer" href="https://www.twiagemed.com">Twiage</a> is a digital health company that provides communication and care coordination technology that enables hospitals, EMS.
        </p>
        <strong className="sub-title">Challenges and goals</strong>
        <h3>In some cases, seconds are tragically important.</h3>
        <p>
          Hospitals need to make specialized preparations for care before patients arrive in the emergency department.
          <br/>
          <a href="#results">View Results</a>
        </p>

        <strong className="sub-title">My role and the team</strong>
        <h3>Working closely with our clients</h3>
        <p>
          The Twiage didn't involve the design team before, so no design processes and assets. I joined as the lead designer, embedded in the development team. I took care of all the design efforts to improve efficiency of communication with external healthcare providers. Furthermore, I organized design processes, and worked closely with the Twiage's PM folks. Besides, I've designed tools for broadcast messaging, telemedicine and advised a redesign of the Hospital Dashboard. Broadcast messaging is the first feature I've worked on, so I wrote a little bit about it.
        </p>

        <strong className="sub-title">Design Process</strong>
        <h3>We don't have and can't collect any data</h3>
        {/* <p>We don't have access to any data (HIPAA). We can't run user tests directly, only through client's partners.</p> */}
        <ul>
          What we knew before we started working:
          <li>Users already had habits, and we could not change anything too much. Major changes may affect the speed of the operator.</li>
          <li>It is important to minimize the number of mistakes by operators.</li>
        </ul>
        <p>
          <strong>The core user story</strong>
          <br/>
          As a Dashboard user, I need to be able to activate a specialized team, so that I can expedite specialized care for a patient before they arrive in the emergency department. 
        </p>
        <p>We used personas to understand the roles and processes in hospitals.
          <br/>
          Each role had its own different working devices.</p>
        <Personas>
          <li>
            <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/twiage/persona1.png"
                alt="Ajay Sharma R.N."
              />
              <strong>Ajay Sharma R.N.</strong>
              <br/>
              48 y.o, always forgets his password<br/>
              Uses dashboard<br/>
              Hospital: Twiage Live Operations Center
          </li>
          <li>
            <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/twiage/persona2.png"
                alt="Irina Mirkina"
              />
              <strong>Irina Mirkina</strong>
              <br/>
              60 y.o.<br/>
              Cardiologist<br/>
              uses STAT<br/>
              Hospitals: Twiage Live Operations Center; Twiage Live Operations Center (Asia)<br/>
              Broadcaster for TLOC
          </li>
          <li>
            <StaticImage
                placeholder="blurred"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../../images/twiage/persona3.png"
                alt="John Dorian (J.D)"
              />
              <strong>John Dorian (J.D)</strong>
              <br/>
              34 y.o.<br/>
              Paramedic, can do video consult<br/>
              Uses EMS app<br/>
              EMS: Works at Twiage Live Operations Center, Twiage Live Operations Center (Asia)
          </li>
        </Personas>
        <strong>Tasks for mapping</strong>
        <ul>
          <li>The task is for Ajay Sharma (Dashboard user) to go through the process of activating the team. </li>
          <li>The task for John Dorian (EMS user) is to bring the patient to an area in the hospital other than the ED.</li>
          <li>The task for Irina Mirkina (STAT user) is to be able to process that information, and prompt chat or physically mobilize inside the hospital. </li>
        </ul>
        <strong>Prototype</strong>
        <p>The UI of the app is based on bootstrap, so it was easy to prepare a high-fidelity prototype with a Sketch library. I created extremely basic prototypes in InVision, with some tailored to match the general device data of our testing candidates.</p>
        <p>The idea was to minimize the number of mistakes for operators by allowing undo. I worked through several different iterations and user flows to see what might be the most meaningful user journey for this test.</p>
        <p>Once our prototype was ready to go into testing, we wanted to put it in the hands of operators as soon as possible. We can't run user tests directly, only through the client's partners.</p>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <img src={ProtoV1} alt="Version #1" className="proto-screen" />
          </div>
        </AnimationOnScroll>
        <br/><br/>
        <p>So, we prepared a detailed guide on how to conduct testing and got the first results. Places where our assumptions broke down, were:</p>
        <ul>
          <li>Undo requires some delay before sending messages. It should be immediate.</li>
          <li>All operators actions should be conscious, with no default values.</li>
          <li>Operators can't broadcast to several hospitals. It is possible according to permissions, but in life not.</li>
        </ul>
        <p>I prepared the next version and ran in-house testing. Thankfully, we had already established a testing workflow that allowed us to explore these prototypes with users quickly.</p>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
          <div className="screen">
            <div className="screen-bar"></div>
            <img src={ProtoV2} alt="Version #2" className="proto-screen" />
          </div>
        </AnimationOnScroll>
        <br/>
        <br/>
        <strong id="results" className="sub-title">Outcomes</strong>
        <h3>A measurable impact</h3>
        <p>Unfortunately, we don't have any production stats, but PM folks shared with us improvements after using Twiage new features.
          <br/>
          Emergency Department door-to-room time has decreased by 60%. I'm proud of the work our team has achieved without any production stats. In summary, most hospitals were excited to see functionality that helps be prepared and save time. And we were really inspired that our work might help save lives.
        </p>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeInUp center" animateOnce="true">
        <div className="screen screen-inline">
          <div className="screen-bar"></div>
          <ReactCompareSlider className="compare"
            itemOne={<ReactCompareSliderImage src={oldDashboard} alt="Old version of Dashboard" />}
            itemTwo={<ReactCompareSliderImage src={newDashboard} alt="New version of Dashboard" />}
          />
        </div>
      </AnimationOnScroll>
      </AnimationOnScroll>
      <Footer />
    </Layout>
  )
}

const Personas = styled.ul`
  list-style: none;
  display: flex;
  margin-left: -10px;

  li {
    width: 33%;
    font-size: 16px;
    padding: 0 10px;
    margin: 0;
  }

  .gatsby-image-wrapper {
    height: 250px;
    margin-bottom: 15px;
    border-radius: 18px;
  }

  @media (max-width: 780px) {
    display: block;
    overflow: hidden;

    li {
      width: 100%;
      clear: both;
      font-size: var(--fontSize-2);
    }

    .gatsby-image-wrapper {
      width: 250px;
      height: 250px;
      float: left;
      margin-right: var(--spacing-6);
    }
  }

  @media (max-width: 580px) {
    li {
      overflow: hidden;
      margin-bottom: 20px;
    }

    .gatsby-image-wrapper {
      width: 100px;
      height: 100px;
      border-radius: 8px;
    }
  }
`

export default IndexPage
