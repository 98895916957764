import * as React from "react"
import Header from '../components/header';
import { Helmet } from 'react-helmet';
import { setSessionPassword, getSessionPassword, getQueryPassword } from '../utils/utils';

if (typeof window !== "undefined") {  
  if (window.location.search && !getSessionPassword()) {
    setSessionPassword(getQueryPassword(window.location).p);
  }
}

const Layout = ({ children, navtype, title, className, location }) => {
  return (
    <>
    <Helmet htmlAttributes={{lang: 'en'}}>
      <title>Rustem — Product designer{title && " - " + title}</title>
      <link rel="canonical" href="https://rustem.design" />
      <meta property="og:title" content={"Rustem — product designer" + (title && " - " + title)} />
    </Helmet>
    <Header location={'inner'} type={navtype} nav={getSessionPassword()} />
    <div className={className + ' global-wrapper'}>
      {children}
    </div>
    </>
  )
}

export default Layout
